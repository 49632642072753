// src/hooks/game/useGameInput.js
import { useState, useEffect, useRef } from 'react';
import { wordService } from '../../services/wordService';
import { useGame } from '../../contexts/GameContext';
import { getExactMatches, buildFullGuess } from '../../utils/game/wordUtils';
import GAME_CONFIG from '../../config';

/**
 * Custom hook for managing game input and word submission logic
 * @param {Function} onLevelComplete - Callback for when a level is completed
 * @returns {Object} Game input state and functions
 */
export function useGameInput(onLevelComplete) {
    const { gameState, updateGameState } = useGame();
    const [userInput, setUserInput] = useState("");
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('normal');
    const [isValidating, setIsValidating] = useState(false);
    const [showCompletionModal, setShowCompletionModal] = useState(false);
    const chainRef = useRef(null);

    // Get current mode configuration
    const modeConfig = GAME_CONFIG.mode;

    // Initialize welcome message
    useEffect(() => {
        if (gameState) {
            if (gameState.isGameOver) {
                const levelReached = gameState.currentLevel + 1;
                const maxLevel = gameState.levels.length;
                if (levelReached >= maxLevel && gameState.levels[gameState.currentLevel].isComplete) {
                    setMessage(`Congratulations! You completed all ${maxLevel} levels and earned your ${modeConfig.rewardName} ${modeConfig.emoji}!`);
                    setMessageType('success');
                } else {
                    setMessage(`Game over! You reached level ${levelReached} out of ${maxLevel}. Try again tomorrow!`);
                    setMessageType('normal');
                }
            } else {
                setMessage(`<span class='pt-1 pe-1'>Ascend the castle and earn your</span> <span class="message-crown">${modeConfig.emoji}</span><span class='pt-1 ps-1'>!</span>`);
                setMessageType('normal');
            }
        }
    }, [gameState, modeConfig]);

    // Get exact matches (green letters) from last guess
    const getGreenLettersFromLastGuess = () => {
        if (!gameState) return new Map();

        const targetWord = gameState.levels[gameState.currentLevel].word;
        const guesses = gameState.levels[gameState.currentLevel].guesses;

        if (guesses.length === 0) return new Map();

        const lastGuess = guesses[guesses.length - 1];
        return getExactMatches(lastGuess, targetWord);
    };

    // Handle keyboard input (used by both physical and virtual keyboards)
    const handleKeyPress = (key) => {
        if (!gameState || isValidating || gameState.isGameOver) return;

        const targetWord = gameState.levels[gameState.currentLevel].word;
        const greenLetters = getGreenLettersFromLastGuess();

        // Count how many positions user needs to fill (non-green positions)
        const editablePositionsCount = targetWord.length - greenLetters.size;

        // Only add the letter if we haven't reached the max input length
        if (userInput.length < editablePositionsCount) {
            setUserInput(userInput + key);
        }
    };

    // Handle backspace
    const handleBackspace = () => {
        if (!gameState || userInput.length === 0 || gameState.isGameOver) return;

        // Simply remove the last character from user input
        setUserInput(userInput.slice(0, -1));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        // If there's an event, prevent default
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (!gameState || gameState.isGameOver) return;

        const targetWord = gameState.levels[gameState.currentLevel].word;
        const fullGuess = buildFullGuess(userInput, getGreenLettersFromLastGuess(), targetWord.length).toUpperCase();
        const currentLevel = gameState.currentLevel;
        const levelData = gameState.levels[currentLevel];

        if (isValidating || levelData.isComplete) return;

        if (fullGuess.length !== targetWord.length) {
            setMessage('Not enough letters');
            setMessageType('error');
            return;
        }

        if (levelData.guesses.includes(fullGuess)) {
            setMessage('Word already guessed');
            setMessageType('error');
            setUserInput('');
            return;
        }

        try {
            setIsValidating(true);
            setMessage('Checking word...');
            setMessageType('normal');

            const isValid = await wordService.isValidWord(fullGuess);

            if (!isValid) {
                setMessage('Not a valid word');
                setMessageType('error');
                setUserInput('');
                setIsValidating(false);
                return;
            }

            const newGuesses = [...levelData.guesses, fullGuess];
            const isComplete = fullGuess === levelData.word;

            // Simplified miss counting logic:
            // If the guess is not the correct word, it counts as a miss
            let countAsMiss = false;
            if (!isComplete) {
                countAsMiss = true;
                const remainingAttempts = GAME_CONFIG.maxMissedAttempts - (levelData.missedAttempts + 1);
                setMessage(`Incorrect! ${remainingAttempts} attempts remaining for Level ${currentLevel + 1}.`);
                setMessageType('error');
            }

            // Save new state using context function
            try {
                const newState = await updateGameState(
                    currentLevel,
                    newGuesses,
                    isComplete,
                    countAsMiss
                );

                // Clear user input
                setUserInput('');

                // Scroll the chain into view after updating state
                if (chainRef.current) {
                    const yOffset = -10;
                    const element = chainRef.current;
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({
                        top: y,
                        behavior: 'smooth'
                    });
                }

                // Handle different outcomes
                if (newState.isGameOver) {
                    if (isComplete && currentLevel === newState.levels.length - 1) {
                        // Completed all levels
                        onLevelComplete(currentLevel);
                        setMessage(`You completed all ${newState.levels.length} levels and earned your ${modeConfig.rewardName}!`);
                        setMessageType('success');
                    } else {
                        // Ran out of attempts on current level
                        setMessage(`Game over! You ran out of attempts on Level ${currentLevel + 1}.`);
                        setMessageType('normal');
                    }

                    // Show completion modal after a delay for a better UX experience
                    setTimeout(() => {
                        setShowCompletionModal(true);
                    }, 2000);
                } else if (isComplete) {
                    // Completed this level, more to go
                    onLevelComplete(currentLevel);
                    setMessage(`Level ${currentLevel + 1} complete! Onto the next word!`);
                    setMessageType('success');
                }
            } catch (saveError) {
                console.error('Save state error:', saveError);
                setMessage('Error saving game state. Please try again.');
                setMessageType('error');
            }
        } catch (error) {
            console.error('Submit error:', error);
            setMessage('Error checking word. Please try again.');
            setMessageType('error');
        } finally {
            setIsValidating(false);
        }
    };

    return {
        userInput,
        message,
        messageType,
        isValidating,
        showCompletionModal,
        setShowCompletionModal,
        handleKeyPress,
        handleBackspace,
        handleSubmit,
        getGreenLettersFromLastGuess,
        chainRef
    };
}

export default useGameInput;
