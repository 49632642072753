// src/components/feedback/MessageDisplay.jsx
import React from 'react';

/**
 * MessageDisplay component
 * Displays game messages with appropriate styling based on message type
 */
function MessageDisplay({ message, messageType, badOnly }) {
    if (!message) return null;
    
    const messageClass = 
        messageType === 'success' ? 'message--success' : 
        messageType === 'error' ? 'message--invalid' : 
				'message--normal';
	
	if (badOnly && messageType !== 'error') return null;
    
    return (
        <div className={`message ${messageClass}`}>
            <div className='flex items-center' dangerouslySetInnerHTML={{ __html: message }} />
        </div>
    );
}

export default MessageDisplay;
