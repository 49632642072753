// src/contexts/GameContext.jsx
import React, { createContext, useContext, useReducer, useEffect, useState, useCallback } from 'react';
import { gameService } from '../services/gameService';
import { statsService } from '../services/statsService';
import timeService from '../services/timeService';
import apiService from '../services/apiService';
import storageService from '../services/storageService';
import { GAME_CONFIG } from '../config';

// Create the context
const GameContext = createContext(null);

// Storage key constants
const CURRENT_DATE_KEY = 'wordcrown_current_date';
const PUZZLE_DATE_KEY = 'wordcrown_puzzle_date';

// Initial state
const initialState = {
    gameState: null,
    isLoading: true,
    error: null,
};

// Game reducer for handling state updates
function gameReducer(state, action) {
    switch (action.type) {
        case 'LOAD_GAME_START':
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case 'LOAD_GAME_SUCCESS':
            return {
                ...state,
                gameState: action.payload,
                isLoading: false,
                error: null,
            };
        case 'LOAD_GAME_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case 'UPDATE_GAME_STATE':
            return {
                ...state,
                gameState: action.payload,
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'CLEAR_ERROR':
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

// Provider component that wraps the app
export function GameProvider({ children }) {
    const [state, dispatch] = useReducer(gameReducer, initialState);
    const [dailyStats, setDailyStats] = useState(null);
    const [lastCheckedTimestamp, setLastCheckedTimestamp] = useState(Date.now());

    // Load the game state - more conservative approach that respects existing state
    const loadGameState = useCallback(() => {
        try {
            dispatch({ type: 'LOAD_GAME_START' });
            const loadedState = gameService.getDailyState();
            dispatch({ type: 'LOAD_GAME_SUCCESS', payload: loadedState });
        } catch (error) {
            console.error('Error loading game state:', error);
            dispatch({ type: 'LOAD_GAME_ERROR', payload: error.message });
        }
    }, []);

    // Load daily stats
    const loadDailyStats = useCallback(async () => {
        try {
            if (gameService.apiEnabled) {
                const stats = await apiService.getDailyStats();
                setDailyStats(stats);
            } else {
                const stats = statsService.getDailyStats();
                setDailyStats(stats);
            }
        } catch (error) {
            console.error('Error loading daily stats:', error);
            // Fallback to local stats
            const stats = statsService.getDailyStats();
            setDailyStats(stats);
        }
    }, []);

    // Initialize game state on component mount
    useEffect(() => {
        loadGameState();
        loadDailyStats();
    }, [loadGameState, loadDailyStats]);

    /**
     * Checks if the current day has changed and we need to refresh the game.
     * This function is more conservative about refreshing to avoid losing user progress.
     * 
     * @param {boolean} isInitialLoad - Whether this is the initial app load
     * @returns {boolean} - True if day changed and state was refreshed
     */
    const checkForNewDay = useCallback((isInitialLoad = false) => {
        // If this is just a routine check and it's been less than 5 minutes since 
        // the last check, skip to avoid too frequent checks
        const now = Date.now();
        if (!isInitialLoad && now - lastCheckedTimestamp < 5 * 60 * 1000) {
            return false;
        }
        setLastCheckedTimestamp(now);

        // Get the current date in Eastern Time
        const currentDate = timeService.getEasternTimeDate(new Date());
        const storedDate = storageService.getItem(CURRENT_DATE_KEY);

        // Get the current puzzle
        const currentPuzzle = GAME_CONFIG.getPuzzleForDate(new Date());
        const storedPuzzleDate = storageService.getItem(PUZZLE_DATE_KEY);

        // Get the existing game state
        const existingState = storageService.getGameState();

        // Detailed logging for debugging
        console.log('Day change check:', {
            currentDate,
            storedDate,
            currentPuzzleDate: currentPuzzle.date,
            storedPuzzleDate,
            haveExistingState: !!existingState
        });

        // CASE 1: No stored date or puzzle date - this is a first run
        if (!storedDate || !storedPuzzleDate) {
            console.log('No stored date or puzzle date - setting up for first time');
            storageService.setItem(CURRENT_DATE_KEY, currentDate);
            storageService.setItem(PUZZLE_DATE_KEY, currentPuzzle.date);
            return false; // Already loading game state at init, no need to reload
        }

        // CASE 2: Date has changed AND:
        // - Either this is initial load
        // - OR the puzzle has actually changed
        //
        // This means we need a fresh game state
        const dateHasChanged = storedDate !== currentDate;
        const puzzleHasChanged = storedPuzzleDate !== currentPuzzle.date;

        if (dateHasChanged && (isInitialLoad || puzzleHasChanged)) {
            console.log('Day has changed and puzzle is different - refreshing game');
            
            // Update the stored dates
            storageService.setItem(CURRENT_DATE_KEY, currentDate);
            storageService.setItem(PUZZLE_DATE_KEY, currentPuzzle.date);
            
            // Only reload if not already loading from initial
            if (!isInitialLoad) {
                loadGameState();
            }
            
            return true;
        }

        // CASE 3: Date has changed but puzzle hasn't (can happen sometimes)
        // Just update the stored date but don't refresh
        if (dateHasChanged && !puzzleHasChanged) {
            console.log('Day has changed but puzzle is the same - updating date only');
            storageService.setItem(CURRENT_DATE_KEY, currentDate);
            return false;
        }

        // CASE 4: Handle initial load with state verification
        if (isInitialLoad && existingState) {
            // Double-check existing state is for current puzzle
            const stateMatchesPuzzle = existingState.levels && 
                                     existingState.levels[0] && 
                                     existingState.levels[0].word === currentPuzzle.levels[0].word;
            
            if (!stateMatchesPuzzle) {
                console.log('Existing state does not match current puzzle - needs refresh');
                storageService.setItem(CURRENT_DATE_KEY, currentDate);
                storageService.setItem(PUZZLE_DATE_KEY, currentPuzzle.date);
                // No need to call loadGameState as it's already being called at initialization
                return true;
            }
        }

        return false; // No change needed
    }, [lastCheckedTimestamp, loadGameState]);

    // Update the game state
    const updateGameState = useCallback(async (levelIndex, guesses, isComplete, countMiss = false) => {
        try {
            const newState = await gameService.saveGameState(
                levelIndex,
                guesses,
                isComplete,
                countMiss
            );

            if (newState) {
                dispatch({ type: 'UPDATE_GAME_STATE', payload: newState });

                // If the game is over, refresh daily stats
                if (newState.isGameOver) {
                    await loadDailyStats();
                }

                return newState;
            } else {
                throw new Error('Failed to update game state');
            }
        } catch (error) {
            console.error('Error updating game state:', error);
            dispatch({ type: 'SET_ERROR', payload: error.message });
            throw error;
        }
    }, [loadDailyStats]);

    // Handle level completion
    const completeLevel = useCallback(async (levelIndex, guesses) => {
        return await updateGameState(levelIndex, guesses, true, false);
    }, [updateGameState]);

    // Handle level failure
    const failLevel = useCallback(async (levelIndex, guesses) => {
        return await updateGameState(levelIndex, guesses, false, true);
    }, [updateGameState]);

    // Check if the user has played before
    const hasPlayedBefore = useCallback(() => {
        return gameService.hasPlayedBefore();
    }, []);

    // Mark the user as having played
    const markAsPlayed = useCallback(() => {
        gameService.markAsPlayed();
    }, []);

    // Reset the game (useful for debugging)
    const resetGame = useCallback(() => {
        storageService.removeItem('wordcrown_game_state');
        storageService.removeItem(CURRENT_DATE_KEY);
        storageService.removeItem(PUZZLE_DATE_KEY);
        loadGameState();
    }, [loadGameState]);

    // Create the context value object with state and functions
    const value = {
        ...state,
        dailyStats,
        updateGameState,
        completeLevel,
        failLevel,
        checkForNewDay,
        loadGameState,
        hasPlayedBefore,
        markAsPlayed,
        resetGame,
    };

    return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
}

// Custom hook for accessing the game context
export const useGame = () => {
    const context = useContext(GameContext);
    if (context === null) {
        throw new Error('useGame must be used within a GameProvider');
    }
    return context;
};
