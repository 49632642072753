// src/services/storageService.js

/**
 * Service for handling all localStorage operations.
 * 
 * Improved with better error handling and recovery mechanisms to prevent
 * data loss when localStorage is full or unavailable.
 */

// Storage keys
const STORAGE_KEYS = {
    GAME_STATE: 'wordcrown_game_state',
    STREAK: 'wordcrown_streak',
    USER_ID: 'wordcrown_user_id',
    HAS_PLAYED: 'wordcrown_has_played',
    DAILY_STATS: 'wordcrown_daily_stats',
    CURRENT_DATE: 'wordcrown_current_date',
    PUZZLE_DATE: 'wordcrown_puzzle_date',
    LAST_CHECKED: 'wordcrown_last_checked',
};

/**
 * Check if localStorage is available and functioning
 * @returns {boolean} Whether localStorage is available
 */
const isLocalStorageAvailable = () => {
    try {
        const testKey = '__storage_test__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

// In-memory fallback for when localStorage is unavailable
const memoryStore = new Map();

/**
 * Centralized storage service for managing localStorage with fallbacks
 */
export const storageService = {
    /**
     * Get an item from localStorage or memory fallback
     * @param {string} key - The storage key
     * @returns {string|null} - The stored value or null if not found
     */
    getItem(key) {
        try {
            // First try localStorage
            if (isLocalStorageAvailable()) {
                return localStorage.getItem(key);
            } else {
                // Fall back to memory store
                console.warn(`localStorage unavailable, using memory store for ${key}`);
                return memoryStore.has(key) ? memoryStore.get(key) : null;
            }
        } catch (error) {
            console.error(`Error getting ${key} from storage:`, error);
            
            // Try memory fallback if localStorage failed
            if (memoryStore.has(key)) {
                console.warn(`Recovered ${key} from memory store`);
                return memoryStore.get(key);
            }
            
            return null;
        }
    },

    /**
     * Set an item in localStorage with memory fallback
     * @param {string} key - The storage key
     * @param {string} value - The value to store
     * @returns {boolean} - Whether the operation was successful
     */
    setItem(key, value) {
        try {
            // Always store in memory as backup
            memoryStore.set(key, value);
            
            // Try localStorage if available
            if (isLocalStorageAvailable()) {
                localStorage.setItem(key, value);
                return true;
            } else {
                console.warn(`localStorage unavailable, ${key} saved only to memory`);
                return true; // We still succeeded with the memory store
            }
        } catch (error) {
            console.error(`Error setting ${key} in localStorage:`, error);
            
            // If it's a quota error, try to clear some space
            if (error instanceof DOMException && 
               (error.code === 22 || error.name === 'QuotaExceededError')) {
                console.warn('Storage quota exceeded, attempting cleanup');
                this.cleanupStorage();
                
                // Try again after cleanup
                try {
                    localStorage.setItem(key, value);
                    return true;
                } catch (retryError) {
                    console.error('Retry failed after cleanup:', retryError);
                    // Still saved in memory, so not a total failure
                    return true;
                }
            }
            
            // Value is still in memory
            return true;
        }
    },

    /**
     * Remove an item from localStorage and memory store
     * @param {string} key - The storage key to remove
     * @returns {boolean} - Whether the operation was successful
     */
    removeItem(key) {
        try {
            // Always remove from memory store
            memoryStore.delete(key);
            
            // Try localStorage if available
            if (isLocalStorageAvailable()) {
                localStorage.removeItem(key);
            }
            return true;
        } catch (error) {
            console.error(`Error removing ${key} from localStorage:`, error);
            // Still removed from memory, so partial success
            return true;
        }
    },
    
    /**
     * Cleanup storage by removing non-essential items
     * Called when storage quota is exceeded
     */
    cleanupStorage() {
        try {
            // Remove stats which are less critical
            localStorage.removeItem(STORAGE_KEYS.DAILY_STATS);
            
            // Clear any development or test data
            const keysToRemove = [];
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key && (key.includes('test_') || key.includes('dev_'))) {
                    keysToRemove.push(key);
                }
            }
            
            keysToRemove.forEach(key => localStorage.removeItem(key));
            
            console.log(`Cleaned up ${keysToRemove.length + 1} items from localStorage`);
            return true;
        } catch (error) {
            console.error('Error during storage cleanup:', error);
            return false;
        }
    },

    /**
     * Get the game state from localStorage with safer parsing
     * @param {string} [key=STORAGE_KEYS.GAME_STATE] - Optional custom key
     * @returns {Object|null} - The parsed game state or null if not found
     */
    getGameState(key = STORAGE_KEYS.GAME_STATE) {
        try {
            const state = this.getItem(key);
            
            // No state found
            if (!state) return null;
            
            // Try to parse the state
            const parsedState = JSON.parse(state);
            
            // Validate parsed state has minimum required fields
            if (parsedState && 
                typeof parsedState === 'object' && 
                Array.isArray(parsedState.levels)) {
                return parsedState;
            } else {
                console.warn('Invalid game state structure:', parsedState);
                return null;
            }
        } catch (error) {
            console.error(`Error parsing game state from ${key}:`, error);
            return null;
        }
    },

    /**
     * Save the game state to localStorage with validation
     * @param {string} key - The storage key
     * @param {Object} state - The game state to save
     * @returns {boolean} - Whether the operation was successful
     */
    saveGameState(key, state) {
        // Validate state before saving
        if (!state || typeof state !== 'object' || !Array.isArray(state.levels)) {
            console.error('Invalid game state structure, not saving:', state);
            return false;
        }
        
        try {
            // Create a direct JSON string to avoid potential circular references
            const stateString = JSON.stringify(state);
            
            // Verify JSON validity by parsing it back
            JSON.parse(stateString);
            
            // Save to storage
            return this.setItem(key, stateString);
        } catch (error) {
            console.error('Error stringifying game state:', error);
            
            // Try to save a simplified version as fallback
            try {
                // Create a minimal valid state
                const fallbackState = {
                    date: state.date || new Date().toISOString(),
                    currentLevel: state.currentLevel || 0,
                    levels: state.levels.map(level => ({
                        word: level.word,
                        guesses: level.guesses || [],
                        isComplete: !!level.isComplete,
                        isLocked: !!level.isLocked
                    }))
                };
                
                console.warn('Saving simplified state as fallback');
                return this.setItem(key, JSON.stringify(fallbackState));
            } catch (fallbackError) {
                console.error('Error saving fallback state:', fallbackError);
                return false;
            }
        }
    },

    /**
     * Get the user ID from localStorage or generate a new one
     * @returns {string} - The user ID
     */
    getUserId() {
        let userId = this.getItem(STORAGE_KEYS.USER_ID);
        if (!userId) {
            userId = 'user_' + Math.random().toString(36).substr(2, 9);
            this.setItem(STORAGE_KEYS.USER_ID, userId);
        }
        return userId;
    },

    /**
     * Get the streak data from localStorage
     * @returns {Object} - The streak data
     */
    getStreakData() {
        const data = this.getItem(STORAGE_KEYS.STREAK);
        return data ? JSON.parse(data) : { streak: 0, lastComplete: null };
    },

    /**
     * Save streak data to localStorage
     * @param {Object} data - The streak data to save
     * @returns {boolean} - Whether the operation was successful
     */
    saveStreakData(data) {
        return this.setItem(STORAGE_KEYS.STREAK, JSON.stringify(data));
    },

    /**
     * Check if the user has played before
     * @returns {boolean} - Whether the user has played before
     */
    hasPlayedBefore() {
        return this.getItem(STORAGE_KEYS.HAS_PLAYED) === 'true';
    },

    /**
     * Mark the user as having played
     * @returns {boolean} - Whether the operation was successful
     */
    markAsPlayed() {
        return this.setItem(STORAGE_KEYS.HAS_PLAYED, 'true');
    },

    /**
     * Get the current date from localStorage
     * @returns {string|null} - The stored date or null if not found
     */
    getCurrentDate() {
        return this.getItem(STORAGE_KEYS.CURRENT_DATE);
    },

    /**
     * Save the current date to localStorage
     * @param {string} date - The date to save
     * @returns {boolean} - Whether the operation was successful
     */
    setCurrentDate(date) {
        return this.setItem(STORAGE_KEYS.CURRENT_DATE, date);
    },

    /**
     * Get the puzzle date from localStorage
     * @returns {string|null} - The stored puzzle date or null if not found
     */
    getPuzzleDate() {
        return this.getItem(STORAGE_KEYS.PUZZLE_DATE);
    },

    /**
     * Save the puzzle date to localStorage
     * @param {string} date - The puzzle date to save
     * @returns {boolean} - Whether the operation was successful
     */
    setPuzzleDate(date) {
        return this.setItem(STORAGE_KEYS.PUZZLE_DATE, date);
    },

    /**
     * Clear all game data from localStorage
     * @returns {boolean} - Whether the operation was successful
     */
    clearGameData() {
        try {
            Object.values(STORAGE_KEYS).forEach(key => {
                this.removeItem(key);
            });
            return true;
        } catch (error) {
            console.error('Error clearing game data:', error);
            return false;
        }
    }
};

export default storageService;
