// src/components/feedback/LevelCompleteOverlay.jsx
import React, { useEffect, useState, useRef } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

/**
 * Modern video game style level completion overlay with enhanced animations
 */
const LevelCompleteOverlay = ({ level, onComplete }) => {
    const [showOverlay, setShowOverlay] = useState(true);
    const containerRef = useRef(null);
    
    useEffect(() => {
        // Hide overlay after animation completes
        const timer = setTimeout(() => {
            setShowOverlay(false);
            if (onComplete) onComplete();
        }, 2700); // Match the updated animation duration in CSS
        
        return () => {
            clearTimeout(timer);
        };
    }, [onComplete]);
    
    // Optional - close on click/escape for dev testing
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setShowOverlay(false);
                if (onComplete) onComplete();
            }
        };
        
        const handleClick = () => {
            setShowOverlay(false);
            if (onComplete) onComplete();
        };
        
        window.addEventListener('keydown', handleKeyDown);
        containerRef.current?.addEventListener('click', handleClick);
        
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            containerRef.current?.removeEventListener('click', handleClick);
        };
    }, [onComplete]);
    
    if (!showOverlay) return null;
    
    return (
        <div className="level-complete-overlay" ref={containerRef}>
            <div className="level-completion-container">
                {/* Check circle with animated checkmark */}
                <div className="level-check-circle">
                    <CheckIcon />
                </div>
                
                <div className="level-complete-box">
                    <h2 className="level-complete-title">
                        {/* Structure with wrapping span to ensure proper animation inheritance */}
                        <span>Level <span className="level-number-highlight">{level}</span></span>
                        <span>Complete</span>
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default LevelCompleteOverlay;
