// src/components/game/WordChain.jsx
import React from 'react';
import { getLetterMatches } from '../../utils/game/wordUtils';
import { GAME_CONFIG } from '../../config/gameConfig';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

const WordChain = ({ words, targetWord }) => {
    return (
        <div className="chain-container">
            <div className="chain-wrapper">
                {words.map((word, index) => (
                    <div key={index} className="word-item">
                        <span className="chain-word-display">
                            {getLetterMatches(word, targetWord).map((match, letterIndex) => (
                                <span 
                                    key={letterIndex}
                                    className={`letter ${
                                        match.isExactMatch ? 'letter--exact-match' : 
                                        (GAME_CONFIG.enableEasyMode && match.isInWord) ? 'letter--in-word' : ''
                                    }`}
                                >
                                    {match.letter}
                                </span>
                            ))}
                        </span>
                        {index < words.length - 1 && 
                            <span className="arrow-separator">
                                <ChevronRightIcon className="h-3 w-3" />
                            </span>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WordChain;
