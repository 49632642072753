// src/components/game/GameControls.jsx
import React from 'react';
import GAME_CONFIG from '../../config';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

/**
 * GameControls component
 * Displays game status indicators and controls
 */
function GameControls({ currentLevel, chainRef }) {
    return (
        <div className="game-header">
            <div className="header-stats">
                <div className="missed-attempts-display" ref={chainRef}>
                    <div className="missed-attempts-label">
                        <ExclamationTriangleIcon className="h-4 w-4 inline-block mr-1" />
                        Misses
                    </div>
                    <div className="missed-attempts-counter">
                        {Array(GAME_CONFIG.maxMissedAttempts).fill(0).map((_, i) => (
                            <span
                                key={i}
                                className={`missed-attempt-dot ${
                                    i < currentLevel.missedAttempts 
                                        ? 'missed-attempt-dot--used' 
                                        : ''
                                }`}
                                aria-label={i < currentLevel.missedAttempts ? "Used attempt" : "Available attempt"}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GameControls;
