// src/components/game/LevelProgress.jsx
import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';

/**
 * Level Progress Component
 * Displays the progress through game levels in a horizontal bar
 */
function LevelProgress({ levels, currentLevel }) {
    return (
        <div className="level-progress">
            {levels.map((level, index) => {
                // Determine level state
                const isLocked = level.isLocked;
                const isComplete = level.isComplete;
                const isCurrent = index === currentLevel;
                
                return (
                    <div
                        key={index}
                        className={`level-item ${
                            isCurrent ? 'level-item--current' : ''
                        } ${isComplete ? 'level-item--completed' : ''} ${
                            isLocked ? 'level-item--locked' : ''
                        }`}
                    >
                        {isLocked && (
                            <div className="level-overlay">
                                <LockClosedIcon className="level-icon level-icon--locked" />
                            </div>
                        )}
                        
                        <div className="level-content">
                            <span className="level-number">
                                {index + 1}
                            </span>
                            
                            {isComplete && (
                                <>
                                    <span className="level-crown" aria-label="Crown">👑</span>
                                    <span className="level-word">{level.word}</span>
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default LevelProgress;
