// src/components/game/VirtualKeyboard.jsx
import React, { useEffect, useState } from 'react';
import { getLetterMatches } from '../../utils/game/wordUtils';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';
import WordChain from './WordChain';
import LevelProgress from './LevelProgress';

const KEYBOARD_LAYOUT = [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    ['BACK', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'ENTER']
];

const VirtualKeyboard = ({
    onKeyPress,
    onSubmit,
    onBackspace,
    guesses = [],
    targetWord,
    currentLevel,
    levels,
}) => {
    const [keyStates, setKeyStates] = useState({});

    // Reset and update key states whenever guesses, targetWord, or currentLevel changes
    useEffect(() => {
        const newKeyStates = {};

        // Track all letters in the target word for easier lookup
        const targetLetters = new Set(targetWord.split(''));

        guesses.forEach(guess => {
            const matches = getLetterMatches(guess, targetWord);

            matches.forEach(match => {
                const letter = match.letter;

                // Don't downgrade a key that's already marked as exact match
                if (newKeyStates[letter] === 'exact-match') {
                    return;
                }

                if (match.isExactMatch) {
                    newKeyStates[letter] = 'exact-match';
                } else if (match.isInWord) {
                    newKeyStates[letter] = 'in-word';
                } else if (!targetLetters.has(letter)) {
                    // Letter is not in the target word at all
                    newKeyStates[letter] = 'not-in-word';
                }
            });
        });

        setKeyStates(newKeyStates);
    }, [guesses, targetWord, currentLevel]);

    const handleKeyClick = (key) => {
        const upperKey = key.toUpperCase();

        // Skip the click if the key is marked as not in word
        if (keyStates[upperKey] === 'not-in-word') {
            return;
        }

        if (upperKey === 'ENTER') {
            onSubmit();
        } else if (upperKey === 'BACK') {
            onBackspace();
        } else {
            onKeyPress(upperKey);
        }
    };

    const getKeyClassName = (key) => {
        const baseClass = 'virtual-keyboard__key';
        const upperKey = key.toUpperCase();

        if (upperKey === 'ENTER') {
            return `${baseClass} virtual-keyboard__key--special virtual-keyboard__key--enter`;
        }
        if (upperKey === 'BACK') {
            return `${baseClass} virtual-keyboard__key--special virtual-keyboard__key--back`;
        }

        const state = keyStates[upperKey];
        return `${baseClass} ${state === 'exact-match' ? 'virtual-keyboard__key--correct' :
                state === 'in-word' ? 'virtual-keyboard__key--present' :
                    state === 'not-in-word' ? 'virtual-keyboard__key--absent' :
                        ''
            }`;
    };

    const renderKeyContent = (key) => {
        if (key.toUpperCase() === 'BACK') {
            return <ArrowUturnLeftIcon className="h-5 w-5" />;
        }
        return <span className="virtual-keyboard__key-text">{key}</span>;
    };

    return (
        <div className="virtual-keyboard-wrapper">
            {/* Add the LevelProgress component here */}
            {levels && (
                <LevelProgress 
                    levels={levels} 
                    currentLevel={currentLevel} 
                />
            )}
            
            {/* Display word chain if there are guesses */}
            {guesses.length > 0 && (
                <WordChain
                    words={guesses}
                    targetWord={targetWord}
                />
            )}
            
            <div className="virtual-keyboard">
                {KEYBOARD_LAYOUT.map((row, rowIndex) => (
                    <div key={rowIndex} className="virtual-keyboard__row">
                        {row.map((key) => (
                            <button
                                key={key}
                                className={getKeyClassName(key)}
                                onClick={() => handleKeyClick(key)}
                                type="button"
                                disabled={keyStates[key.toUpperCase()] === 'not-in-word'}
                            >
                                {renderKeyContent(key)}
                            </button>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VirtualKeyboard;
