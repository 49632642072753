// src/config/puzzles.js
// Collection of all puzzles organized by date
import timeService from '../services/timeService';

export const PUZZLES = [
    {
        date: "04/03/2025",
        category: "Fears",
        levels: [
            { word: 'GHOST' },
            { word: 'SNAKE' },
            { word: 'DEATH' },
            { word: 'SPIDER' },
            { word: 'CANCER' },
            { word: 'INSECT' },
            { word: 'THUNDER' },
            { word: 'FAILURE' }
        ]
    },
    {
        date: "04/04/2025",
        category: "Types of fabric",
        levels: [
            { word: 'NYLON' },
            { word: 'KHAKI' },
            { word: 'DENIM' },
            { word: 'COTTON' },
            { word: 'BURLAP' },
            { word: 'LEATHER' },
            { word: 'CASHMERE' },
            { word: 'POLYESTER' }
        ]
    },
    {
        date: "04/05/2025",
        category: "Shades of gray",
        levels: [
            { word: 'SHARK' },
            { word: 'SLATE' },
            { word: 'SMOKE' },
            { word: 'STEEL' },
            { word: 'NICKEL' },
            { word: 'PEWTER' },
            { word: 'SILVER' },
            { word: 'CHARCOAL' }
        ]
    },
    {
        date: "04/06/2025",
        category: "Mashland creatues",
        levels: [
            { word: 'SNAKE' },
            { word: 'SNAIL' },
            { word: 'TURTLE' },
            { word: 'BEAVER' },
            { word: 'LIZARD' },
            { word: 'TADPOLE' },
            { word: 'PELICAN' },
            { word: 'MOSQUITO' }
        ]
    },
    {
        date: "04/07/2025",
        category: "Science experiment",
        levels: [
            { word: 'FLASK' },
            { word: 'LASER' },
            { word: 'PRISM' },
            { word: 'CLOCK' },
            { word: 'MAGNET' },
            { word: 'BEAKER' },
            { word: 'CIRCUIT' },
            { word: 'CRYSTAL' }
        ]
	},
	{
        date: "04/08/2025",
        category: "Car parts",
        levels: [
            { word: 'PEDAL' },
            { word: 'BRAKE' },
            { word: 'VALVE' },
            { word: 'ENGINE' },
            { word: 'CLUTCH' },
            { word: 'GASKET' },
            { word: 'CONSOLE' },
            { word: 'IGNITION' }
        ]
    },
];

// Helper function to get puzzle for a specific date
export function getPuzzleForDate(date) {
    // Convert the input date (or current date) to Eastern Time
    const targetDate = date || new Date();
    
    // Use timeService for Eastern Time formatting
    const formattedTargetDate = timeService.getEasternDateForPuzzle(targetDate);
    
    // Try to find an exact match for today's date (in Eastern Time)
    let puzzle = PUZZLES.find(puzzle => puzzle.date === formattedTargetDate);
    
    // If no exact match, find the most recent puzzle before today
    if (!puzzle) {
        // Convert all puzzle dates to Date objects for comparison
        const puzzleDates = PUZZLES.map(p => ({
            puzzle: p,
            date: new Date(p.date)
        }));
        
        // Get current date in Eastern Time for comparison
        const etDateParts = formattedTargetDate.split('/');
        const etDate = new Date(
            parseInt(etDateParts[2]), // year
            parseInt(etDateParts[0]) - 1, // month (0-indexed)
            parseInt(etDateParts[1]) // day
        );
        
        // Sort by date, most recent first
        puzzleDates.sort((a, b) => b.date - a.date);
        
        // Find the most recent puzzle before target date
        const mostRecent = puzzleDates.find(p => p.date <= etDate);
        
        // Use the most recent puzzle if found, otherwise use the first puzzle
        puzzle = mostRecent ? mostRecent.puzzle : PUZZLES[0];
    }
    
    return puzzle;
}
