// src/components/layout/AppHeader.jsx
import React from 'react';
import { QuestionMarkCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { MessageDisplay } from '../feedback';

/**
 * AppHeader component
 * Contains the app logo and global controls like help button and feedback button
 */
function AppHeader({
    onOpenHowToPlay,
    onOpenFeedback,
    message,
    messageType
}) {
    return (
        <div className="app-header">
            <div className="app-header-controls">
                {/* Left side - Help button */}
                <button
                    className="help-button-global"
                    onClick={onOpenHowToPlay}
                    aria-label="How to play"
                >
                    <QuestionMarkCircleIcon className="help-icon" />
                </button>

                {/* Right side - Feedback button */}
                <button
                    className="feedback-button"
                    onClick={onOpenFeedback}
                    aria-label="Send feedback"
                >
                    <PencilSquareIcon />
                    <span>Feedback</span>
                </button>
            </div>

            <div className="wordcrown-logo">
                <img src="/logo.png" alt="Word Crown logo" />
            </div>

            {/* Message to the user */}
            <MessageDisplay
                message={message}
                messageType={messageType}
            />
        </div>
    );
}

export default AppHeader;
